export default [
  {
    name: "name",
    required: true,
    label: "Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.name
  },
  {
    name: "priority",
    required: true,
    label: "Order",
    align: "left",
    sortable: true,
    field: (i: any) => i.priority
  },
  {
    name: "maxScore",
    required: true,
    label: "Max Score",
    align: "left",
    sortable: false,
    field: (i: any) => i.maxScore
  },
  {
    name: "description",
    label: "Description",
    align: "left",
    sortable: true,
    style: "inline-size: 150px;overflow-wrap: break-word;word-break: break-all; ",
    field: (i: any) => i.description
  },
  {
    name: "weight",
    required: true,
    label: "Weight",
    align: "left",
    sortable: false,
    field: (i: any) => i.weight,
  }
];
