








































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import draggable from "vuedraggable";
import { Criterion, OpenCall } from "@/data/models/OpenCall";
import DatePicker from "@/components/DatePicker.vue";
import FileInput from "@/components/FileInput.vue";
import CriterionColumns from "@/data/columns/Criteria";
import ModelTable from "@/components/ModelTable.vue";
import CriterionForm from "@/components/forms/CriterionForm.vue";

@Component({
  components: {
    FormContainer,
    DatePicker,
    FileInput,
    ModelTable,
    CriterionForm,
    draggable
  }
})
export default class ChildCriteria extends Vue {
  private provider: DataProvider<Criterion> | null = null;
  private criteriaProvider: DataProvider<Criterion> | null = null;

  @Prop()
  public crud!: ICrudClient<Criterion>;

  @Prop()
  public id!: string;

  @Prop()
  public openCallId!: string;

  private columns = CriterionColumns;
  private openCriterionDialog = false;
  private item = null;

  async mounted() {
    this.provider = this.$service.providers.openCallChildCriteria(
      this.openCallId,
      this.id
    );
    this.criteriaProvider = this.$service.providers.openCallCriteria(
      this.openCallId
    );

    //
  }
  reset() {
    this.openCriterionDialog = false;
    this.provider = this.$service.providers.openCallChildCriteria(
      this.openCallId,
      this.id
    );
  }
}
