














































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { ActionType } from "@/data/models/ActionTypes";
import EntitySelect from "@/components/EntitySelect.vue";
import DatePicker from "@/components/DatePicker.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import { Criterion } from "@/data/models/OpenCall";
import { max } from "moment";

@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect,
    DatePicker,
    DynamicForm
  }
})
export default class CriterionForm extends Vue {
  @Prop()
  public item!: any;

  @Prop()
  public crud!: ICrudClient<Criterion>;

  @Prop()
  public id!: string;

  @Prop()
  public openCallId!: string;

  @Prop()
  public provider!: DataProvider<Criterion>;

  private parentCriteria: any[] = [];
  private childCriteria: any[] = [];
  private maxScore: any = 100;
  private minScore: any = 1;
  private existingParentId = this.item.parentCriterionId;

  @Watch("item.parentCriterionId")
  onParentChange() {
    this.calculateMaxScore();
  }

  async mounted() {
    if (this.id === 'new') {
      this.item.openCallId = this.openCallId
    }
    else {
      this.childCriteria = (
      await this.getChildCriteria(this.item.id)).items;
    }
    
    this.parentCriteria = (
      await this.getParentCriteria()
    ).items;
    
    this.calculateMaxScore();
  }

  async submit() {
    if (!this.item.id) {
      await this.$service.crud.criteria.addAsync([this.item]);
    } else {
      await this.$service.crud.criteria.saveAsync(this.item);
    }
  }

  private async calculateMaxScore() {
    this.maxScore = 100;
    this.minScore = 1;
    if (this.item.parentCriterionId) {
      const criterion = this.parentCriteria.filter(
        x => x.id === this.item.parentCriterionId
      )[0];
      const scores = criterion.childCriteria
        .map((x: any) => x.maxScore)
        .reduce((a: any, b: any) => a + b, 0);
      const currentScore = this.item.maxScore && this.item.parentCriterionId === this.existingParentId ? this.item.maxScore : 0;
      this.maxScore = criterion.maxScore - scores + currentScore;
    }

    if (this.childCriteria) {
      const scoreToreduce = this.childCriteria.map((x: any) => x.maxScore)
        .reduce((a: any, b: any) => a + b, 0);
        this.minScore = scoreToreduce;
    }
  }

  async getChildCriteria(id: any) {
    return await this.provider.fetchItemsAsync({
        columnFilters: [
          { column: "parentCriterionId", value: `${id}`, op: "Equals" }
        ]
      });
  }

  async getParentCriteria() {
    return await this.provider.fetchItemsAsync({
        columnFilters: [
          { column: "parentCriterionId", value: `null`, op: "Equals" }
        ]
    })
  }
}
