








































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import draggable from "vuedraggable";
import { Project } from "@/data/models/Projects";
import { Guid } from "guid-typescript";
import { Criterion, OpenCall } from "@/data/models/OpenCall";
import DatePicker from "@/components/DatePicker.vue";
import FileInput from "@/components/FileInput.vue";
import CriterionColumns from "@/data/columns/Criteria";
import ModelTable from "@/components/ModelTable.vue";
import CriterionForm from "@/components/forms/CriterionForm.vue";
import ChildCriteriaTable from "./ChildCriteriaTable.vue";

@Component({
  components: {
    FormContainer,
    DatePicker,
    FileInput,
    ModelTable,
    CriterionForm,
    draggable,
    ChildCriteriaTable
  }
})
export default class Criteria extends Vue {
  private criteriaProvider: DataProvider<Criterion> | null = null;
  private provider: DataProvider<Criterion> | null = null;

  @Prop()
  public crud!: ICrudClient<Criterion>;

  @Prop()
  public id!: string;

  @Prop()
  public openCallId!: string;

  private columns = CriterionColumns;
  private addCriterionDialog = false;

  private item = {
    openCallId: this.openCallId
  };
  reset() {
    this.item = {
      openCallId: this.openCallId
    };
    this.addCriterionDialog = false;
    this.provider = this.$service.providers.openCallParentCriteria(
      this.openCallId
    );
  }
  async mounted() {
    this.provider = this.$service.providers.openCallParentCriteria(
      this.openCallId
    );
    this.criteriaProvider = this.$service.providers.openCallCriteria(
      this.openCallId
    );
    //
  }
}
