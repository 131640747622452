var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('model-table',_vm._b({attrs:{"title":"Parent Criteria","provider":_vm.provider,"crud":_vm.crud,"columns":_vm.columns,"sortBy":'Priority',"selection":'multiple',"filter":"openCallId eq ","allowExpand":true},scopedSlots:_vm._u([{key:"row-actions",fn:function(ref){
var scope = ref.scope;
return [_c('q-btn',{attrs:{"icon":"visibility","flat":"","no-caps":""},on:{"click":function($event){_vm.item = scope.row;
          _vm.addCriterionDialog = true;}}},[_c('q-tooltip',[_vm._v("Open")])],1),_vm._t("row-buttons",null,null,{ scope: scope })]}},{key:"top-actions",fn:function(){return [_c('q-btn',{staticClass:"bg-green-5 text-white",attrs:{"icon":"add","label":"Add"},on:{"click":function($event){_vm.addCriterionDialog = true}}})]},proxy:true},{key:"expand-button",fn:function(ref){
          var scope = ref.scope;
return [_c('q-btn',{attrs:{"flat":"","no-caps":"","icon":scope.expand ? 'expand_less' : 'expand_more'},on:{"click":function($event){scope.expand = !scope.expand}}},[_c('q-badge',{attrs:{"color":"orange","floating":"","transparent":""}},[_vm._v(" "+_vm._s(scope.row["childCriteria@odata.count"])+" ")]),_c('q-tooltip',[_vm._v(" View Child Criteria ")])],1)]}},{key:"expanded",fn:function(ref){
          var scope = ref.scope;
return [_c('child-criteria-table',{staticStyle:{"margin-bottom":"20px"},attrs:{"crud":_vm.crud,"id":scope.row.id,"openCallId":_vm.openCallId,"viewPermission":function (r) { return true; }}})]}}],null,true)},'model-table',Object.assign({}, _vm.$props, _vm.$attrs),false)),_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.addCriterionDialog),callback:function ($$v) {_vm.addCriterionDialog=$$v},expression:"addCriterionDialog"}},[_c('criterion-form',{attrs:{"provider":_vm.criteriaProvider,"id":_vm.item.id ? _vm.id : 'new',"openCallId":_vm.openCallId,"item":_vm.item},on:{"update:item":function($event){_vm.item=$event},"save":function($event){return _vm.reset()},"close":function($event){return _vm.reset()}}}),_c('q-btn',{on:{"click":function($event){_vm.addCriterionDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }